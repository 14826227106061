import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { css } from '@emotion/core'

import 'twin.macro'

import Marquee from '../components/marquee'
import Button from '../components/button'

import LogoImg from '../svgs/logo.svg'

const NotFoundPage = ({ data }) => {
  const { errorPage } = data

  return (
    <>
      <Helmet>
        <title>This Place of Mine | Page not found</title>
      </Helmet>
      <main tw="fixed inset-0 overflow-hidden font-body font-bold bg-brand-blue text-white flex flex-col justify-between">
        <Marquee>
          this page is missing or does not exist&nbsp;&nbsp;this page is missing
          or does not exist&nbsp;&nbsp;this page is missing or does not exist
        </Marquee>
        <div tw="max-w-2xl px-8 lg:px-16 pb-8 lg:pb-16">
          <LogoImg
            tw="mb-8 xl:mb-16 h-auto"
            css={css`
              width: 190px;
              @media (min-width: 1300px) {
                width: 250px;
              }
            `}
          />
          <div
            tw="text-lg xl:text-2xl mb-8 xl:mb-16"
            dangerouslySetInnerHTML={{
              __html: errorPage.body,
            }}
          ></div>
          <Button text="let's go home" to="/" />
        </div>
      </main>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    errorPage: craftErrorPageGlobalSet {
      body
    }
  }
`
